import { useState, useEffect } from "react";

import { select, update } from "easy-db-browser";
import { useSnackbar } from "notistack";

import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
// import Autocomplete from "@material-ui/lab/Autocomplete";

import Alert from "@material-ui/lab/Alert";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import Fab from "@material-ui/core/Fab";
import ReplayIcon from "@material-ui/icons/Replay";
import LinearProgress from "@material-ui/core/LinearProgress";

import { api, useApi } from "./api";

export default function App() {
    const { enqueueSnackbar } = useSnackbar();

    const [ loading, setLoading ] = useState(false);

    const [ user, setUser ] = useState("");
    const [ scoring, setScoring ] = useState("");
    const [ sample, setSample ] = useState("");
    const [ note, setNote ] = useState("");

    const [ rating, setRating ] = useState(0);
    const [ taste, setTaste ] = useState<string[]>([]);

    const [ users, errU ] = useApi.getUsers({});
    const [ scoringList, errSc ] = useApi.getScoring({});
    const [ samples, errSa ] = useApi.getSample({ scoring });

    // const [ tasteList ] = useApi.getTaste({});

    // load user name
    useEffect(() => {
        select<{ user: string }>("gin", "user").then(row => {
            if (row) setUser(row.user);
        });
    }, []);

    return <>
        <div className="gin">
            <div className="logos">
                <img src="/images/landcraft.png" className="landcraft" />
                <img src="/images/datamole-text.png" className="datamole" />
            </div>
            <h1><LocalBarIcon fontSize="large" /> GIN Rating</h1>

            {errU && <Alert severity="error">{errU}</Alert>}
            {errSc && <Alert severity="error">{errSc}</Alert>}
            {errSa && <Alert severity="error">{errSa}</Alert>}

            <Fab color="secondary" onClick={() => global.location.reload()}>
                <ReplayIcon />
            </Fab>

            <Grid container spacing={3}>
                {users === null && <LinearProgress />}
                {users && <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="user-label">User</InputLabel>
                        <Select
                            labelId="user-label"
                            id="user-select"
                            value={user}
                            onChange={e => {
                                const user = String(e.target.value);
                                setUser(user);
                                update("gin", "user", { user });
                            }}
                        >
                            {users.map(user => <MenuItem key={user} value={user}>{user}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>}

                {user && <>
                    {scoringList === null && <LinearProgress />}
                    {scoringList && <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Scoring batch</InputLabel>
                            <Select
                                value={scoring}
                                onChange={e => {
                                    setScoring(String(e.target.value));
                                    setSample("");
                                }}
                            >
                                {scoringList.map(s => <MenuItem key={s} value={s}>{Number(s.substr(7)) + 1}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>}

                    {scoring && <>

                        {samples === null && <LinearProgress />}
                        {samples && <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Sample</InputLabel>
                                <Select
                                    value={sample}
                                    onChange={e => setSample(String(e.target.value))}
                                >
                                    {samples.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>}

                        {sample && <>
                            <Grid item xs={12}>
                                <Typography id="discrete-slider" gutterBottom>
                                    Tap to rate
                                </Typography>
                                <div className="rating">
                                    <Rating
                                        size="large"
                                        name="rating"
                                        precision={0.5}
                                        value={rating}
                                        onChange={(event, newValue) => {
                                            if (newValue !== null)
                                                setRating(newValue);
                                        }}
                                    />
                                </div>
                            </Grid>

                            {/* <Grid item xs={12}>
                                {tasteList && <Autocomplete
                                    multiple
                                    fullWidth
                                    id="taste"
                                    options={tasteList}
                                    getOptionLabel={(taste) => taste}
                                    value={taste}
                                    onChange={(_, taste) => setTaste(taste)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Taste"
                                        />
                                    )}
                                />}
                            </Grid> */}

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={3}
                                    label="Describe your emotion about the drink"
                                    value={note}
                                    onChange={e => setNote(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                {loading && <LinearProgress />}
                                {!loading && <Button
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                    disabled={rating === 0}
                                    onClick={async () => {
                                        setLoading(true);
                                        try {
                                            await api.addRating({ user, sample, scoring, rating, taste, note });
                                            enqueueSnackbar("Rating sent, you are cool 👍", { variant: "success" });
                                            setSample("");
                                            setRating(0);
                                            setTaste([]);
                                            setNote("");
                                        } catch (e) {
                                            enqueueSnackbar("Nooo, something went wrong.", { variant: "error" });
                                        }
                                        setLoading(false);
                                    }}
                                >
                                    Send rating
                                </Button>}
                            </Grid>
                        </>}
                    </>}
                </>}
            </Grid>
        </div>
        <div className="ocean-wrap">
            <div className="ocean">
                <div className="wave"></div>
                <div className="wave"></div>
            </div>
        </div>
    </>;
}
